import { Merchant, Store } from '@bofrak-backend/shared';
import { NavBar, useAuth } from '@bofrak-backend/shared-ui';
import { VStack } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useQuery } from 'react-query';
import { Route, Routes, useLocation } from 'react-router-dom';
import { useRecoilState, useSetRecoilState } from 'recoil';
import { apiAdapter } from './api';
import ProductList from './components/items/products';
import ProductListPage from './components/items/products/AddProduct';
import Loader from './components/resuable/Loader';
import { STAGE } from './constants/environment';
import { routes } from './constants/routes';
import {
  accessTokenAtom,
  adminAtom,
  merchantAtom,
  selectedStoreAtom,
} from './recoil/atoms';

function App() {
  const query = new URLSearchParams(useLocation().search);

  const [storeId, setStoreId] = useState<string | null>(null);
  const setSelectedStore = useSetRecoilState(selectedStoreAtom);
  const [merchant, setMerchant] = useRecoilState(merchantAtom);
  const setAccessToken = useSetRecoilState(accessTokenAtom);
  const setAdmin = useSetRecoilState(adminAtom);

  const { employee, signOut } = useAuth({
    applicationName: merchant?.business_name ?? '',
    stage: STAGE,
  });

  useEffect(() => {
    setAdmin(employee);
    const storeIdFromUrl = query.get('store_id');
    if (storeIdFromUrl) {
      setStoreId(storeIdFromUrl);
    }
  }, [query]);

  useQuery(
    `storeId_${storeId}`,
    () => apiAdapter.getStore(storeId!, employee!.merchant_id),
    {
      enabled: !!storeId && !!employee,
      onSuccess: (data) => {
        console.log('🚀 ~ file: App.tsx:57 ~ App ~ data:', data);

        setSelectedStore(data as Store);
      },
    },
  );

  useQuery(
    `get-merchant-${employee?.id}`,
    () => apiAdapter.getMerchant(employee?.merchant_id as string),
    {
      enabled: !!employee,
      onSuccess: (data) => {
        setMerchant(data as Merchant);
      },
    },
  );

  useEffect(() => {
    async function onStartup() {
      const urlParams = new URLSearchParams(window.location.search);
      const encryptedToken = urlParams.get('token');

      if (encryptedToken) {
        try {
          const decryptedObject = await apiAdapter.decryptUserData({
            encryptedCredentials: encryptedToken,
          });

          setAccessToken(decryptedObject.AccessToken);
          // store in local storage
          localStorage.setItem('accessToken', decryptedObject.AccessToken);
        } catch (error) {
          console.error('Failed to decrypt token:', error);
        }
      }
    }
    onStartup();
  }, []);

  return employee ? (
    <>
      <NavBar
        appName="Items"
        homePath={routes.home}
        merchant={merchant ? merchant.business_name : ''}
        signOut={signOut}
        setCurrentStore={setSelectedStore}
        stage={STAGE}
        storeId={storeId ?? ''}
        user={employee}
        merchantId={merchant?.id ?? employee.merchant_id}
      />

      <Routes>
        <Route path={'/items'} element={<ProductList />} />
        <Route path={'/'} element={<ProductList />} />
        <Route path={routes.newProduct} element={<ProductListPage />} />
      </Routes>
    </>
  ) : (
    <VStack height={'100vh'} width={'full'}>
      <Loader />
    </VStack>
  );
}

export default App;
