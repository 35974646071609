export const BASE_URL = import.meta.env.VITE_BASE_URL;
export const IMAGE_BASE_URL = import.meta.env.VITE_IMG_URL;

export const routes = {
  items: '/items',
};

export const STAGE = import.meta.env.VITE_STAGE;
export const REGION = import.meta.env.VITE_REGION;
export const COGNITO_USER_POOL_ID = import.meta.env.VITE_COGNITO_USER_POOL_ID;
export const USER_POOL_CLIENT_ID = import.meta.env.VITE_USER_POOL_CLIENT_ID;
export const COGNITO_IDENTITY_POOL_ID = import.meta.env
  .VITE_COGNITO_IDENTITY_POOL_ID;
