// CustomDatePicker.tsx
import { colors } from '@bofrak-backend/shared';
import {
  Box,
  Button,
  ButtonGroup,
  Input,
  Popover,
  PopoverContent,
  PopoverTrigger,
  VStack,
  useDisclosure,
} from '@chakra-ui/react';
import { Global, css } from '@emotion/react';
import { format } from 'date-fns';
import React, { useState } from 'react';
import DatePicker from 'react-datepicker';

// Import the React DatePicker styles (base styles)
import 'react-datepicker/dist/react-datepicker.css';

// Define the component's props interface
interface CustomDatePickerProps {
  isRange?: boolean;
  onDateChosen: (startDate: Date, endDate?: Date) => void;
  initialDate?: Date;
  initialEndDate?: Date;
}

export const CustomDatePicker: React.FC<CustomDatePickerProps> = ({
  isRange = false,
  onDateChosen,
  initialDate,
  initialEndDate,
}) => {
  // Set default date range from one week before to now
  const [startDate, setStartDate] = useState<Date | null>(
    initialDate || new Date(new Date().setDate(new Date().getDate() - 7)),
  );
  const [endDate, setEndDate] = useState<Date | null>(
    initialEndDate || new Date(),
  );
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [isStartDateSet, setIsStartDateSet] = useState(false);

  // Quick selection options (same as before)
  const quickOptions = isRange
    ? [
        { label: 'Today', value: [startOfDay(new Date()), new Date()] },
        {
          label: 'This Week',
          value: [startOfWeek(new Date()), endOfWeek(new Date())],
        },
        {
          label: 'This Month',
          value: [startOfMonth(new Date()), endOfMonth(new Date())],
        },
        {
          label: 'This Year',
          value: [startOfYear(new Date()), endOfYear(new Date())],
        },
      ]
    : [
        { label: 'Today', value: new Date() },
        {
          label: 'Yesterday',
          value: new Date(new Date().setDate(new Date().getDate() - 1)),
        },
        {
          label: '2 Days Ago',
          value: new Date(new Date().setDate(new Date().getDate() - 2)),
        },
        {
          label: '3 Days Ago',
          value: new Date(new Date().setDate(new Date().getDate() - 3)),
        },
        // Add more as needed
      ];

  // Handle date change
  const handleDateChange = (
    update: [Date | null, Date | null] | Date | null,
  ) => {
    if (Array.isArray(update)) {
      console.log({ start: update[0], end: update[1] });
    } else {
      console.log({ update });
    }

    if (isRange) {
      if (Array.isArray(update)) {
        const [start, end] = update;
        setStartDate(start);
        setEndDate(end);
        setIsStartDateSet(true);
      } else if (update instanceof Date) {
        if (startDate && !endDate) {
          // Update startDate's time
          setStartDate(update);
          setIsStartDateSet(true);
        } else if (startDate && endDate) {
          if (isStartDateSet) {
            // Update endDate's time
            setEndDate(update);
            setIsStartDateSet(false);
          } else {
            // Update startDate's time
            setStartDate(update);
            setIsStartDateSet(true);
          }
        }
      }
    } else {
      if (update instanceof Date) {
        setStartDate(update);
        onDateChosen && onDateChosen(update);
      }
    }
  };

  // Format display text
  const displayValue = isRange
    ? startDate && endDate
      ? `${format(startDate, 'Pp')} - ${format(endDate, 'Pp')}`
      : 'Select date range'
    : startDate
      ? format(startDate, 'Pp')
      : 'Select date';

  return (
    <Box>
      {/* Inject global styles for react-datepicker */}
      <Global
        styles={css`
          .react-datepicker {
            border: none;
            font-family: 'Inter', sans-serif;
          }

          .react-datepicker__header {
            background-color: white;
            border-bottom: none;
          }

          .react-datepicker__current-month,
          .react-datepicker-time__header {
            font-size: 1rem;
            font-weight: bold;
          }

          .react-datepicker__day-name,
          .react-datepicker__day,
          .react-datepicker__time-name {
            width: 2.5rem;
            line-height: 2.5rem;
          }

          .react-datepicker__day--selected,
          .react-datepicker__day--in-selecting-range,
          .react-datepicker__day--in-range {
            background-color: #3182ce;
            color: white;
          }

          .react-datepicker__day--keyboard-selected {
            background-color: #63b3ed;
          }

          .react-datepicker__time-container
            .react-datepicker__time
            .react-datepicker__time-box
            ul.react-datepicker__time-list
            li.react-datepicker__time-list-item--selected {
            background-color: #3182ce;
            color: white;
          }

          /* Adjust the inline display */
          .react-datepicker-popper {
            z-index: 1500;
          }
        `}
      />
      <Popover isOpen={isOpen} onClose={onClose}>
        <PopoverTrigger>
          <Input
            minW={isRange ? '230px' : '100px'}
            value={displayValue}
            onClick={onOpen}
            readOnly
            cursor="pointer"
            fontSize={isRange ? 'xx-small' : 'md'}
          />
        </PopoverTrigger>
        <PopoverContent width="auto">
          <VStack p={4}>
            {/* Quick Selection Buttons */}
            <ButtonGroup size="sm" variant="outline">
              {quickOptions.map((option) => (
                <Button
                  key={option.label}
                  onClick={() => {
                    handleDateChange(option.value as any);
                    onClose();
                  }}>
                  {option.label}
                </Button>
              ))}
            </ButtonGroup>

            {/* Date Picker */}
            <DatePicker
              selected={!isRange ? startDate : startDate || endDate}
              onChange={handleDateChange}
              startDate={startDate}
              endDate={endDate}
              selectsRange={isRange}
              showTimeSelect
              timeIntervals={1}
              inline
            />

            {/* Apply and Restart Buttons */}
            <ButtonGroup mt={2} size="sm">
              {isRange && (
                <Button
                  borderRadius={'full'}
                  bg={colors.red}
                  color={'white'}
                  onClick={() => {
                    // Reset startDate and endDate to default values
                    setStartDate(null);
                    setEndDate(null);
                    setIsStartDateSet(false);
                  }}>
                  Restart
                </Button>
              )}
              <Button
                borderRadius={'full'}
                bg={colors.green}
                color={'white'}
                onClick={() => {
                  if (isRange) {
                    if (startDate && endDate) {
                      onDateChosen && onDateChosen(startDate, endDate);
                      onClose();
                    } else {
                      // Optionally, show a validation message
                    }
                  } else {
                    if (startDate) {
                      onDateChosen && onDateChosen(startDate);
                      onClose();
                    }
                  }
                }}
                isDisabled={isRange ? !(startDate && endDate) : !startDate}>
                Apply
              </Button>
            </ButtonGroup>
          </VStack>
        </PopoverContent>
      </Popover>
    </Box>
  );
};

// Helper functions for date calculations
function startOfDay(date: Date): Date {
  const result = new Date(date);
  result.setHours(0, 0, 0, 0);
  return result;
}

function startOfWeek(date: Date): Date {
  const result = new Date(date);
  const day = result.getDay();
  const diff = result.getDate() - day + (day === 0 ? -6 : 1);
  result.setDate(diff);
  result.setHours(0, 0, 0, 0);
  return result;
}

function endOfWeek(date: Date): Date {
  const result = startOfWeek(date);
  result.setDate(result.getDate() + 6);
  result.setHours(23, 59, 59, 999);
  return result;
}

function startOfMonth(date: Date): Date {
  const result = new Date(date.getFullYear(), date.getMonth(), 1);
  result.setHours(0, 0, 0, 0);
  return result;
}

function endOfMonth(date: Date): Date {
  const result = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  result.setHours(23, 59, 59, 999);
  return result;
}

function startOfYear(date: Date): Date {
  const result = new Date(date.getFullYear(), 0, 1);
  result.setHours(0, 0, 0, 0);
  return result;
}

function endOfYear(date: Date): Date {
  const result = new Date(date.getFullYear(), 11, 31);
  result.setHours(23, 59, 59, 999);
  return result;
}
