import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalCloseButton,
  ModalBody,
} from '@chakra-ui/react';

interface ModalComponentProps {
  children: React.ReactNode;
  isOpen: boolean;
  onClose: () => void;
  size?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'full';
}

export default function ModalComponent({
  children,
  isOpen,
  onClose,
  size = 'sm',
}: ModalComponentProps) {
  return (
    <>
      <Modal isOpen={isOpen} onClose={onClose} size={size}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody maxW={'100vw'} my={12}>
            {children}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
