import { colors } from '@bofrak-backend/shared';
import { useEffect, useState } from 'react';

interface BlinkingDotProps {
  isGreen: boolean;
  enableBlinking: boolean;
}

const BlinkingDot = ({ isGreen, enableBlinking }: BlinkingDotProps) => {
  const [isVisible, setIsVisible] = useState(true);

  useEffect(() => {
    if (enableBlinking) {
      const interval = setInterval(() => {
        setIsVisible((prev) => !prev);
      }, 1000); // Toggle visibility every second
      return () => clearInterval(interval); // Cleanup on unmount
    } else {
      setIsVisible(true); // Ensure it's always visible if blinking is disabled
    }
  }, [enableBlinking]);

  return (
    <div
      style={{
        margin: '0',
        padding: '0',
        width: '10px',
        height: '10px',
        borderRadius: '50%',
        backgroundColor: isGreen ? colors.green : colors.red,
        opacity: enableBlinking && !isVisible ? 0 : 1, // Blink if enabled
        transition: 'opacity 0.5s ease', // Smooth blinking effect
      }}></div>
  );
};

export default BlinkingDot;
