import { EntityTypes, SortOrder, Timestamps } from './shared.types';

export enum InventoryChangeReason {
  SALE = 'Sale',
  REFUND = 'Refund',
  PURCHASE_ORDER = 'Purchase Order',
  INVENTORY_TRANSFER = 'Inventory Transfer',
  INVENTORY_COUNT = 'Inventory Count',
  INVENTORY_CORRECTION = 'Inventory Correction',
}

export enum ReasonCounted {
  SALE = 'Sale',
  REFUND = 'Refund',
  PURCHASE = 'Purchase Order',
  INVENTORY_TRANSFER = 'Inventory Transfer',
  INVENTORY_COUNT = 'Inventory Count',
  INVENTORY_CORRECTION = 'Inventory Correction',
}

export enum InventoryChangeEvent {
  INVENTORY_CHANGE_QUANTITY_UPDATED = 'INVENTORY_CHANGE_QUANTITY_UPDATED',
  INVENTORY_CUMULATIVE_QUANTITY_UPDATED = 'INVENTORY_CUMULATIVE_QUANTITY_UPDATED',
  INVENTORY_CHANGE_REASON_UPDATED = 'INVENTORY_CHANGE_REASON_UPDATED',
  INVENTORY_CHANGE_SOURCE_UPDATED = 'INVENTORY_CHANGE_SOURCE_UPDATED',
  INVENTORY_CHANGE_DELETED_AT_UPDATED = 'INVENTORY_CHANGE_DELETED_AT_UPDATED',
  INVENTORY_CHANGE_SOURCE_ID_UPDATED = 'INVENTORY_CHANGE_SOURCE_ID_UPDATED',
  INVENTORY_CHANGE_SOURCE_TYPE_UPDATED = 'INVENTORY_CHANGE_SOURCE_TYPE_UPDATED',
}

export interface InventoryChange extends Timestamps {
  id: string; // Unique change_id
  store_id: string;
  product_id: string;
  product_name: string;
  change_quantity: number;
  cumulative_quantity: number;
  reason: InventoryChangeReason;
  source_type: EntityTypes;
  merchant_id: string;
  created_at: string; // ISO 8601 date string
  type: 'CHANGE';
  // Additional attributes
  source_id: string;
  cost: number;
  source_name: string;
}

export interface CreateInventoryChange {
  store_id: string;
  product_id: string;
  change_quantity: number;
  reason: InventoryChangeReason;
  source_type: EntityTypes;
  merchant_id: string;
  source_id: string;
  cost: number;
  source_name: string;
  product_name: string;
}

export interface CurrentInventory extends Timestamps {
  id: string;
  store_id: string;
  product_id: string;
  cumulative_quantity: number;
  merchant_id: string;
  type: 'CURRENT';
}

export interface InventoryChangeDBItem extends InventoryChange {
  // PK: `STORE#${store_id}#PRODUCT#${product_id}`
  // SK: `INVENTORY_CHANGE#DATE#${created_at}#INVENTORY_CHANGE#${id}`
  PK: string;
  SK: string;

  // GSI1PK: `PRODUCT#${product_id}`
  // GSI1SK: `INVENTORY_CHANGE#STORE#${store_id}#DATE#${created_at}`
  GSI1PK: string;
  GSI1SK: string;

  // GSI2PK: `REASON#${reason}#STORE#${store_id}`
  // GSI2SK: `INVENTORY_CHANGE#PRODUCT#${product_id}#DATE#${created_at}`
  GSI2PK: string;
  GSI2SK: string;

  // GSI3PK: `SOURCE#${source}#STORE#${store_id}`
  // GSI3SK: `INVENTORY_CHANGE#PRODUCT#${product_id}#DATE#${created_at}`
  GSI3PK: string;
  GSI3SK: string;

  // GSI4PK: `REASON#${reason}#SOURCE#${source}#STORE#${store_id}`
  // GSI4SK: `INVENTORY_CHANGE#PRODUCT#${product_id}#DATE#${created_at}`
  GSI4PK: string;
  GSI4SK: string;

  // GSI5PK: `REASON#${reason}`
  // GSI5SK: `INVENTORY_CHANGE#PRODUCT#${product_id}#STORE#${store_id}#DATE#${created_at}`
  GSI5PK: string;
  GSI5SK: string;

  // GSI6PK: `SOURCE#${source}`
  // GSI6SK: `INVENTORY_CHANGE#PRODUCT#${product_id}#STORE#${store_id}#DATE#${created_at}`
  GSI6PK: string;
  GSI6SK: string;

  // GSI7PK: `REASON#${reason}#SOURCE#${source}`
  // GSI7SK: `INVENTORY_CHANGE#PRODUCT#${product_id}#STORE#${store_id}#DATE#${created_at}`
  GSI7PK: string;
  GSI7SK: string;

  // EntityIndexPK: `INVENTORY_CHANGE`
  // EntityIndexSK: `MERCHANT#${merchant_id}#STORE#${store_id}#PRODUCT#${product_id}#DATE#${created_at}`
  EntityIndexPK: string;
  EntityIndexSK: string;
}

export interface CurrentInventoryDBItem extends CurrentInventory {
  // PK: `STORE#${store_id}#PRODUCT#${product_id}`
  // SK: `TYPE#CURRENT`
  PK: string;
  SK: string;

  // GSI1PK: `PRODUCT#${product_id}`
  // GSI1SK: `TYPE#CURRENT#STORE#${store_id}`
  GSI1PK: string;
  GSI1SK: string;

  EntityIndexPK: string;
  EntityIndexSK: string;
}

export interface GetInventoryChanges {
  /**
   * Flags to determine which query to perform.
   * Only one should be true per request.
   */
  by_store_and_product_and_date_range?: boolean;
  by_store_and_date_range?: boolean;
  by_reason_and_store_and_product_and_date_range?: boolean;

  by_reason_and_store?: boolean;
  by_reason_across_stores?: boolean;
  by_source_and_store?: boolean;
  by_source_across_stores?: boolean;
  by_reason_and_source_and_store?: boolean;
  by_reason_and_source_across_stores?: boolean;
  by_product_and_store?: boolean;
  by_product_across_stores?: boolean;
  by_reason_and_product_and_store?: boolean;
  by_reason_and_product_across_stores?: boolean;
  by_source_and_product_and_store?: boolean;
  by_source_and_product_across_stores?: boolean;
  by_source_and_reason_and_product?: boolean;
  by_source_and_reason_and_store?: boolean;
  by_source_and_reason_across_stores?: boolean;

  // ... other query flags as needed ...

  /**
   * Common query parameters.
   */
  limit?: number;
  cursor?: string;
  sort_order?: SortOrder;

  /**
   * Filter parameters.
   */
  store_id?: string;
  product_id?: string;
  reason?: InventoryChangeReason;
  source_type?: EntityTypes;
  source_id?: string;
  merchant_id: string; // For merchant-level queries

  start_date?: string; // ISO 8601 date string
  end_date?: string; // ISO 8601 date string
}

export interface InventoryChangePage {
  changes: InventoryChange[];
  cursor?: string;
  count: number;
}
