import { EntityTypes, Image } from '@bofrak-backend/shared';
import {
  Image as ChakraImage,
  Flex,
  IconButton,
  Input,
  Text,
  Box,
} from '@chakra-ui/react';
import React, { useRef, useState, useEffect } from 'react';
import { FaCamera, FaPencilAlt } from 'react-icons/fa';
import { apiAdapter } from '../../../api';

interface UploadImageProps {
  image_id?: string;
  merchant_id: string;
  entity_type: EntityTypes;
  entity_id: string;
  currentImage?: string; // Optional current image URL
  onImageUpload: (image: Image) => Promise<void>;
}

const UploadImage: React.FC<UploadImageProps> = ({
  image_id,
  merchant_id,
  entity_type,
  entity_id,
  currentImage,
  onImageUpload,
}) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const [previewImage, setPreviewImage] = useState<string | null>(null);
  const [uploadedImageUrl, setUploadedImageUrl] = useState<string | null>(
    currentImage || null,
  );
  const [base64Image, setBase64Image] = useState<string | null>(null);

  // Upload image if conditions are met
  useEffect(() => {
    const uploadImage = async () => {
      if (entity_id && entity_type && base64Image) {
        const imageId = image_id || `${Date.now()}`;
        const uploadedImage = await apiAdapter.uploadImage({
          image_id: imageId,
          base64Image,
          entity_type,
          entity_id,
          merchant_id,
        });

        // Retain the uploaded image URL in state
        setUploadedImageUrl(uploadedImage.image_url);
        setPreviewImage(null);
        setBase64Image(null);

        // Trigger the callback with the uploaded image details
        await onImageUpload(uploadedImage);
      }
    };

    uploadImage();
  }, [
    entity_id,
    entity_type,
    base64Image,
    image_id,
    merchant_id,
    onImageUpload,
  ]);

  const handleIconClick = () => {
    inputRef.current?.click();
  };

  const handleFileChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    if (event.target.files && event.target.files.length > 0) {
      const file = event.target.files[0];

      // Preview the selected image
      const reader = new FileReader();
      reader.onload = () => {
        if (reader.result) {
          setPreviewImage(reader.result.toString());
        }
      };
      reader.readAsDataURL(file);

      // Convert image to base64
      const base64 = await new Promise<string>((resolve, reject) => {
        const fileReader = new FileReader();
        fileReader.onload = () => resolve(fileReader.result?.toString() || '');
        fileReader.onerror = reject;
        fileReader.readAsDataURL(file);
      });

      setBase64Image(base64);
    }
  };

  return (
    <Flex
      justify="center"
      mb={4}
      border="1px solid"
      borderColor="gray.400"
      borderRadius="5px"
      padding={4}>
      <Flex justify="center" direction="column" align="center" mb={4}>
        {previewImage || uploadedImageUrl ? null : (
          <Text color="gray.500" textAlign="center" fontSize="sm" width="150px">
            Upload Product Image
          </Text>
        )}
        {(previewImage || uploadedImageUrl) && (
          <Box position="relative" width="150px" height="150px">
            <ChakraImage
              src={previewImage || uploadedImageUrl || ''}
              alt="Image"
              boxSize="150px"
              mb={4}
              objectFit="cover"
              borderRadius="md"
              onError={
                // If the image fails to load, set the preview image to null and the uploaded image URL to null as well to prevent the image from being displayed
                () => {
                  setPreviewImage(null);
                  setUploadedImageUrl(null);
                }
              }
            />
            <IconButton
              aria-label="Edit image"
              icon={
                <FaPencilAlt
                  style={{
                    color: 'green', // Only the icon is green
                  }}
                />
              }
              position="absolute"
              top="5px"
              right="5px"
              size="sm"
              variant="ghost" // No background styling
              onClick={handleIconClick}
              cursor="pointer"
              isRound
            />
          </Box>
        )}
        {!previewImage && !uploadedImageUrl && (
          <IconButton
            aria-label="Upload image"
            icon={<FaCamera />}
            colorScheme="gray"
            size="lg"
            variant="outline"
            onClick={handleIconClick}
          />
        )}
        <Input
          type="file"
          accept="image/*"
          borderRadius={0}
          _focus={{ boxShadow: 'none' }}
          ref={inputRef}
          display="none"
          onChange={handleFileChange}
        />
      </Flex>
    </Flex>
  );
};

export default UploadImage;
