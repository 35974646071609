import React, { useEffect } from 'react';
import { Controller, useForm, useWatch } from 'react-hook-form';
import {
  Box,
  Button,
  Input,
  VStack,
  useToast,
  Text,
  Flex,
  Switch,
} from '@chakra-ui/react';
import {
  CreateProduct,
  CreateProductFraction,
  CreateStoreProduct,
  StoreProduct,
} from '@bofrak-backend/shared';
import { useMutation, useQueryClient } from 'react-query';
import { useRecoilValue } from 'recoil';
import {
  adminAtom,
  merchantAtom,
  selectedStoreAtom,
} from '../../../recoil/atoms';
import { apiAdapter } from '../../../api';

type FormData = {
  fraction: number;
  price: number;
  cost: number;
  unit: string;
  itemsInUnit: number;
  is_sold_by_weight: boolean;
  weight?: number;
};

type Props = {
  item: StoreProduct;
  closeForm: () => void;
};

const FractionForm: React.FC<Props> = ({ item, closeForm }) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const currentStore = useRecoilValue(selectedStoreAtom);
  const merchant = useRecoilValue(merchantAtom);
  const employee = useRecoilValue(adminAtom);

  const {
    handleSubmit,
    register,
    reset,
    control,
    setValue,
    formState: { errors },
  } = useForm<FormData>({
    defaultValues: {
      fraction: 1,
      cost: item.cost,
    },
  });

  const itemsInUnit = useWatch({ control, name: 'itemsInUnit' });
  const fraction = useWatch({ control, name: 'fraction' });

  useEffect(() => {
    if (itemsInUnit) {
      const calculatedFraction = itemsInUnit / item.quantity_in_unit_package;
      setValue('fraction', calculatedFraction);
      setValue('cost', item.cost * calculatedFraction);
    }
  }, [itemsInUnit, item.quantity_in_unit_package, item.cost, setValue]);

  const createProductMutation = useMutation((data: CreateProduct) =>
    apiAdapter.createProduct(data),
  );

  const createFractionMutation = useMutation((data: CreateProductFraction) =>
    apiAdapter.createProductFraction({
      createProductFractionDto: data,
      merchant_id: merchant?.id || '',
    }),
  );

  const createStoreProductMutation = useMutation((data: CreateStoreProduct) =>
    apiAdapter.createStoreProduct({
      createStoreProductDto: data,
      merchant_id: merchant?.id as string,
    }),
  );

  const onSubmit = async (data: FormData) => {
    if (!employee || !merchant || !currentStore) {
      toast({
        title: 'Required data not found',
        description: 'Employee, Merchant, or Store data is missing.',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const taxes = item.taxes.map((tax) =>
        typeof tax === 'string' ? tax : tax.id,
      );

      // Step 1: Create Product
      toast({
        title: 'Creating product...',
        status: 'info',
        duration: 3000,
        isClosable: true,
      });

      const productData: CreateProduct = {
        name: item.name,
        description: `Sub product of ${item.name}`,
        image: item.image,
        barcode: Math.round(1000000000 * Math.random()),
        quantity_in_unit_package: data.itemsInUnit,
        weight_kg: data.weight || 0,
        unit: data.unit,
        creator_id: employee.id,
        merchant_id: merchant.id,
        taxes,
        category_id: item.category_id,
      };

      const product = await createProductMutation.mutateAsync(productData);

      if (!product?.id) throw new Error('Product creation failed');

      toast({
        title: 'Product created successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      // Step 2: Create Product Fraction
      toast({
        title: 'Creating product fraction...',
        status: 'info',
        duration: 3000,
        isClosable: true,
      });

      const fractionData: CreateProductFraction = {
        fraction_id: product.id, // Using product.id here
        parent_id: item.parent_id,
        creator_id: employee.id,
        merchant_id: merchant.id,
      };

      const fractionResponse =
        await createFractionMutation.mutateAsync(fractionData);

      if (!fractionResponse?.created_at)
        throw new Error('Product Fraction creation failed');

      toast({
        title: 'Product fraction created successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      // Step 3: Create Store Product
      toast({
        title: 'Creating store product...',
        status: 'info',
        duration: 3000,
        isClosable: true,
      });

      const storeProductData: CreateStoreProduct = {
        product_id: product.id,
        store_id: currentStore.id as string,
        inventory: item.inventory,
        is_available_for_sale: item.inventory > data.fraction,
        is_sold_by_range: false,
        max_selling_price: data.price,
        min_selling_price: data.cost,
        is_manually_controlled: false,
        is_sold_by_weight: data.is_sold_by_weight || false,
        is_sold_online: false,
        loyalty_programs: [],
        price: data.price,
        cost: data.cost,
        low_stock_threshold: 10,
        merchant_id: merchant.id as string,
        creator_id: employee.id as string,
      };

      const storeProduct =
        await createStoreProductMutation.mutateAsync(storeProductData);

      if (!storeProduct?.id) throw new Error('Store Product creation failed');

      toast({
        title: 'Store product created successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      // Invalidate queries
      queryClient.invalidateQueries(`get-product-${item?.id}`);
      queryClient.invalidateQueries('store-products');

      toast({
        title: 'All operations completed successfully',
        status: 'success',
        duration: 3000,
        isClosable: true,
      });

      reset();
      closeForm();
    } catch (error: unknown) {
      console.error(error);

      const err = error as Error;

      toast({
        title: 'Error',
        description: err?.message || 'An error occurred',
        status: 'error',
        duration: 3000,
        isClosable: true,
      });
    }
  };

  return (
    <Box maxW="full" mx="auto" bg="blue.50" p={4} borderRadius="md">
      <VStack spacing={4}>
        <Flex justify="space-between" align="center" bg="white" p={2}>
          <Flex direction="column" align="center">
            <Input
              borderRadius={0}
              _focus={{ boxShadow: 'none' }}
              borderRight={0}
              borderLeft={0}
              fontSize="xs"
              borderTop={0}
              placeholder="Enter Price"
              {...register('price', {
                required: 'Price is required',
                validate: (value) =>
                  value >= (item.cost * fraction || item.cost)
                    ? true
                    : 'Price must be greater than or equal to cost',
                pattern: {
                  value: /^[0-9]+(\.[0-9]{1,2})?$/,
                  message: 'Enter a valid price',
                },
              })}
            />
            <Text color="gray.500" fontSize="xs">
              Price
            </Text>
            {errors.price && (
              <Text color="red.500" fontSize="xs">
                {errors.price.message}
              </Text>
            )}
          </Flex>

          <Flex direction="column" align="center">
            <Input
              borderRadius={0}
              _focus={{ boxShadow: 'none' }}
              borderRight={0}
              borderLeft={0}
              borderTop={0}
              fontSize="xs"
              isReadOnly
              value={(item.cost * fraction || item.cost).toFixed(2)}
              placeholder="Cost"
              {...register('cost')}
            />
            <Text color="gray.500" fontSize="xs" mb={2}>
              Cost
            </Text>
          </Flex>
        </Flex>

        <Flex justify="space-between" align="center" bg="white" p={2} gap={3}>
          <Flex direction="column" align="center">
            <Input
              borderRadius={0}
              _focus={{ boxShadow: 'none' }}
              borderRight={0}
              type="text"
              borderLeft={0}
              borderTop={0}
              fontSize="xs"
              placeholder="Unit"
              {...register('unit', {
                required: 'Unit is required',
                pattern: {
                  value: /^(?=.*[a-zA-Z-=/])([a-zA-Z0-9-=/\s]+)$/,
                  message:
                    'Unit must contain letters, numbers, or symbols (-, /, =)',
                },
              })}
            />
            <Text color="gray.500" fontSize="xs">
              Unit
            </Text>
            {errors.unit && (
              <Text color="red.500" fontSize="xs">
                {errors.unit.message}
              </Text>
            )}
          </Flex>

          <Flex direction="column" align="center">
            <Input
              borderRadius={0}
              _focus={{ boxShadow: 'none' }}
              borderRight={0}
              borderLeft={0}
              borderTop={0}
              fontSize="xs"
              placeholder="Items in unit"
              {...register('itemsInUnit', {
                required: 'Items in unit are required',
                validate: (value) =>
                  value <= item.quantity_in_unit_package
                    ? true
                    : 'Items in unit must be less than or equal to parent quantity',
              })}
            />
            <Text color="gray.500" fontSize="xs">
              Items in Unit
            </Text>
            {errors.itemsInUnit && (
              <Text color="red.500" fontSize="xs">
                {errors.itemsInUnit.message}
              </Text>
            )}
          </Flex>
        </Flex>

        <Flex justify="space-between" align="center" bg="white" p={2} w="full">
          <Text fontSize="xs" color="gray.400">
            Sold by Weight
          </Text>
          <Controller
            name="is_sold_by_weight"
            control={control}
            render={({ field }) => (
              <Switch
                id={field.name}
                colorScheme="teal"
                isChecked={field.value}
                onChange={(e) => field.onChange(e.target.checked)}
                onBlur={field.onBlur}
                ref={field.ref}
              />
            )}
          />
        </Flex>

        <Flex justify="center" align="center" gap={3} mb={3} w="full">
          <Button
            colorScheme="teal"
            isLoading={
              createProductMutation.isLoading ||
              createStoreProductMutation.isLoading ||
              createFractionMutation.isLoading
            }
            onClick={handleSubmit(onSubmit)}>
            Submit
          </Button>

          <Button colorScheme="gray" onClick={closeForm}>
            Close
          </Button>
        </Flex>
      </VStack>
    </Box>
  );
};

export default FractionForm;
