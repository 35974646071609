/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  colors,
  CreateStoreProduct,
  Product,
  ProductFraction,
  Tax,
} from '@bofrak-backend/shared';
import {
  Box,
  Button,
  Card,
  CardBody,
  CardFooter,
  CardHeader,
  Divider,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Image,
  List,
  ListItem,
  NumberInput,
  NumberInputField,
  Stack,
  Switch,
  Text,
  useToast,
  VStack,
} from '@chakra-ui/react';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useRecoilValue } from 'recoil';
import { apiAdapter } from '../../../api';
import {
  adminAtom,
  merchantAtom,
  selectedStoreAtom,
} from '../../../recoil/atoms';

interface Prop {
  selectedProduct: Product;
  onCloseCreateModal: () => void;
}

const CreateImportProduct = ({ selectedProduct, onCloseCreateModal }: Prop) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const merchant = useRecoilValue(merchantAtom);
  const employee = useRecoilValue(adminAtom);
  const store = useRecoilValue(selectedStoreAtom);

  const [taxes, setTaxes] = useState<Tax[]>([]);

  // Fetch all taxes for the merchant
  useQuery(
    ['taxes', merchant?.id],
    () => apiAdapter.getTaxes({ merchant_id: merchant?.id as string }),
    {
      onSuccess: (data) => {
        setTaxes(data?.taxes);
      },
      enabled: !!merchant?.id,
    },
  );

  const {
    register,
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      inventory: 0,
      is_available_for_sale: false,
      is_sold_by_range: false,
      max_selling_price: 0,
      min_selling_price: 0,
      is_manually_controlled: false,
      is_sold_by_weight: false,
      is_sold_online: false,
      price: 0,
      cost: 0,
      low_stock_threshold: 1,
    },
  });

  const createStoreProductMutation = useMutation(
    (data: CreateStoreProduct) =>
      apiAdapter.createStoreProduct({
        merchant_id: data.merchant_id,
        createStoreProductDto: data,
      }),
    {
      onSuccess: (data) => {
        if (!data) {
          toast({
            title: 'Error importing product',
            description: 'An error occurred',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });

          return;
        }

        toast({
          title: 'Product Imported',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
        onCloseCreateModal();
        queryClient.invalidateQueries('store-products');
      },
      onError: (error: Error | AxiosError) => {
        toast({
          title: 'Error importing product',
          description:
            error instanceof AxiosError
              ? error?.response?.data?.message
              : error.message || 'An error occurred',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      },
    },
  );

  const onSubmit = (formData: any) => {
    const createStoreProductData: CreateStoreProduct = {
      creator_id: employee?.id as string,
      product_id: selectedProduct.id,
      store_id: store?.id as string,
      inventory: formData.inventory,
      is_available_for_sale: formData.is_available_for_sale,
      is_sold_by_range: formData.is_sold_by_range,
      max_selling_price: formData.max_selling_price,
      min_selling_price: formData.min_selling_price,
      is_manually_controlled: formData.is_manually_controlled,
      is_sold_by_weight: formData.is_sold_by_weight,
      is_sold_online: formData.is_sold_online,
      loyalty_programs: [],
      price: formData.price,
      cost: formData.cost,
      low_stock_threshold: formData.low_stock_threshold,
      merchant_id: merchant?.id as string,
    };

    createStoreProductMutation.mutate(createStoreProductData);
  };

  // Helper function to get tax details
  const getTaxDetails = (taxId: string): Tax | undefined => {
    return taxes.find((tax) => tax.id === taxId);
  };

  return (
    <Box width={'full'} as="form" onSubmit={handleSubmit(onSubmit)}>
      {/* Product Details Card */}
      <Card>
        <CardHeader>
          <Flex alignItems="center">
            <Image
              src={selectedProduct.image}
              alt={selectedProduct.name}
              boxSize="80px"
              objectFit="cover"
              mr={4}
            />
            <VStack spacing={0}>
              <Text
                width={'full'}
                textAlign={'left'}
                fontSize="2xl"
                fontWeight="bold">
                {selectedProduct.name}
              </Text>
              <Text width={'full'} textAlign={'left'} color={'gray.500'}>
                {selectedProduct.description}
              </Text>
            </VStack>
          </Flex>
        </CardHeader>
        <CardBody>
          <Stack spacing={1}>
            <VStack spacing={2} divider={<Divider />}>
              {selectedProduct.barcode && (
                <HStack width={'full'} justifyContent={'space-between'}>
                  <Text fontWeight={'bold'}>Barcode</Text>
                  <Text>{selectedProduct.barcode}</Text>
                </HStack>
              )}

              {selectedProduct.global_sku && (
                <HStack width={'full'} justifyContent={'space-between'}>
                  <Text fontWeight={'bold'}>SKU</Text>
                  <Text>{selectedProduct.global_sku}</Text>
                </HStack>
              )}
              {selectedProduct.quantity_in_unit_package && (
                <HStack width={'full'} justifyContent={'space-between'}>
                  <Text fontWeight={'bold'}>Qty in Pckg</Text>
                  <Text>{selectedProduct.quantity_in_unit_package}</Text>
                </HStack>
              )}
              {selectedProduct.weight_kg && (
                <HStack width={'full'} justifyContent={'space-between'}>
                  <Text fontWeight={'bold'}>Weight</Text>
                  <Text>{selectedProduct.weight_kg} kg</Text>
                </HStack>
              )}
              {selectedProduct.unit && (
                <HStack width={'full'} justifyContent={'space-between'}>
                  <Text fontWeight={'bold'}>Unit </Text>
                  <Text>{selectedProduct.unit}</Text>
                </HStack>
              )}
            </VStack>
            <Divider />

            {/* Product Fractions */}
            {selectedProduct.product_fractions &&
              selectedProduct.product_fractions.length > 0 && (
                <Box>
                  <Text fontWeight="bold">Product Fractions</Text>
                  <List spacing={2} pl={4}>
                    {selectedProduct.product_fractions.map(
                      (fraction: ProductFraction) => (
                        <ListItem key={fraction.fraction_id}>
                          <HStack justifyContent={'space-between'}>
                            <Text fontWeight={'semibold'}>
                              {fraction.fraction_name}
                            </Text>
                            <Text>
                              {selectedProduct.quantity_in_unit_package *
                                fraction.fraction}
                            </Text>
                          </HStack>
                        </ListItem>
                      ),
                    )}
                  </List>
                </Box>
              )}

            {/* Taxes */}
            {selectedProduct.taxes &&
              selectedProduct.taxes.length > 0 &&
              taxes.length > 0 && (
                <Box>
                  <Text fontWeight="bold">Taxes:</Text>
                  <List spacing={2} pl={4}>
                    {selectedProduct.taxes.map((taxItem: Tax | string) => {
                      let taxData: Tax | undefined;

                      if (typeof taxItem === 'string') {
                        taxData = getTaxDetails(taxItem);
                      } else {
                        taxData = taxItem;
                      }

                      if (taxData) {
                        return (
                          <ListItem key={taxData.id}>
                            <Text>
                              {taxData.name} ({taxData.type})
                            </Text>
                          </ListItem>
                        );
                      } else {
                        return null;
                      }
                    })}
                  </List>
                </Box>
              )}
          </Stack>
        </CardBody>
      </Card>

      {/* Divider */}
      <Divider my={6} />

      {/* Form to Create Store Product */}
      <Card>
        <CardHeader>
          <Text textAlign={'center'} fontSize="xl" fontWeight="bold">
            Customise Product
          </Text>
        </CardHeader>
        <CardBody>
          {/* Inline Form */}
          <Stack spacing="1">
            <Stack spacing="1" direction={{ base: 'column', md: 'row' }}>
              {/* Inventory */}
              <FormControl isInvalid={!!errors.inventory}>
                <FormLabel>Inventory</FormLabel>
                <NumberInput min={0}>
                  <NumberInputField
                    {...register('inventory', { valueAsNumber: true })}
                  />
                </NumberInput>
              </FormControl>

              {/* Price */}
              <FormControl isInvalid={!!errors.price}>
                <FormLabel>Price</FormLabel>
                <NumberInput min={0}>
                  <NumberInputField
                    {...register('price', { valueAsNumber: true })}
                  />
                </NumberInput>
              </FormControl>
            </Stack>

            <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
              {/* Cost */}
              <FormControl isInvalid={!!errors.cost}>
                <FormLabel>Cost</FormLabel>
                <NumberInput min={0}>
                  <NumberInputField
                    {...register('cost', { valueAsNumber: true })}
                  />
                </NumberInput>
              </FormControl>

              {/* Low Stock Threshold */}
              <FormControl isInvalid={!!errors.low_stock_threshold}>
                <FormLabel>Low Stock Threshold</FormLabel>
                <NumberInput min={0}>
                  <NumberInputField
                    {...register('low_stock_threshold', {
                      valueAsNumber: true,
                    })}
                  />
                </NumberInput>
              </FormControl>
            </Stack>

            <Stack spacing="6" direction={{ base: 'column', md: 'row' }}>
              {/* Min Selling Price */}
              <FormControl isInvalid={!!errors.min_selling_price}>
                <FormLabel>Min Selling Price</FormLabel>
                <NumberInput min={0}>
                  <NumberInputField
                    {...register('min_selling_price', { valueAsNumber: true })}
                  />
                </NumberInput>
              </FormControl>

              {/* Max Selling Price */}
              <FormControl isInvalid={!!errors.max_selling_price}>
                <FormLabel>Max Selling Price</FormLabel>
                <NumberInput min={0}>
                  <NumberInputField
                    {...register('max_selling_price', { valueAsNumber: true })}
                  />
                </NumberInput>
              </FormControl>
            </Stack>

            {/* Switches */}
            <VStack>
              <FormControl
                display="flex"
                width="full"
                justifyContent={'space-between'}>
                <FormLabel htmlFor="is_available_for_sale" mb="0">
                  Available for Sale
                </FormLabel>
                <Controller
                  name="is_available_for_sale"
                  control={control}
                  render={({ field }) => (
                    <Switch
                      id="is_available_for_sale"
                      isChecked={field.value}
                      onChange={(e) => field.onChange(e.target.checked)}
                    />
                  )}
                />
              </FormControl>

              <FormControl
                display="flex"
                width="full"
                justifyContent={'space-between'}>
                <FormLabel htmlFor="is_sold_by_range" mb="0">
                  Sold by Range
                </FormLabel>
                <Controller
                  name="is_sold_by_range"
                  control={control}
                  render={({ field }) => (
                    <Switch
                      id="is_sold_by_range"
                      isChecked={field.value}
                      onChange={(e) => field.onChange(e.target.checked)}
                    />
                  )}
                />
              </FormControl>

              <FormControl
                display="flex"
                width="full"
                justifyContent={'space-between'}>
                <FormLabel htmlFor="is_manually_controlled" mb="0">
                  Manually Controlled
                </FormLabel>
                <Controller
                  name="is_manually_controlled"
                  control={control}
                  render={({ field }) => (
                    <Switch
                      id="is_manually_controlled"
                      isChecked={field.value}
                      onChange={(e) => field.onChange(e.target.checked)}
                    />
                  )}
                />
              </FormControl>

              <FormControl
                display="flex"
                width="full"
                justifyContent={'space-between'}>
                <FormLabel htmlFor="is_sold_by_weight" mb="0">
                  Sold by Weight
                </FormLabel>
                <Controller
                  name="is_sold_by_weight"
                  control={control}
                  render={({ field }) => (
                    <Switch
                      id="is_sold_by_weight"
                      isChecked={field.value}
                      onChange={(e) => field.onChange(e.target.checked)}
                    />
                  )}
                />
              </FormControl>
              <FormControl
                display="flex"
                width="full"
                justifyContent={'space-between'}>
                <FormLabel htmlFor="is_sold_online" mb="0">
                  Sold Online
                </FormLabel>
                <Controller
                  name="is_sold_online"
                  control={control}
                  render={({ field }) => (
                    <Switch
                      id="is_sold_online"
                      isChecked={field.value}
                      onChange={(e) => field.onChange(e.target.checked)}
                    />
                  )}
                />
              </FormControl>
            </VStack>
          </Stack>
        </CardBody>
        <CardFooter>
          <HStack width={'full'} justifyContent={'center'}>
            <Button
              type="submit"
              borderRadius={'full'}
              width={'150px'}
              color="white"
              bg={colors.green}
              isLoading={createStoreProductMutation.isLoading}>
              Import Product
            </Button>
            <Button
              borderRadius={'full'}
              width={'150px'}
              color="white"
              bg={colors.red}
              onClick={onCloseCreateModal}>
              Cancel
            </Button>
          </HStack>
        </CardFooter>
      </Card>
    </Box>
  );
};

export default CreateImportProduct;
