// Header.tsx

import { Employee } from '@bofrak-backend/shared';

import {
  Box,
  Flex,
  HStack,
  IconButton,
  Spacer,
  Text,
  useColorModeValue,
} from '@chakra-ui/react';
import { useState } from 'react';
import { VscSignIn, VscSignOut } from 'react-icons/vsc';
import { Link } from 'react-router-dom';
import { MenuTrigger } from './menu-trigger';

export const NavBar = ({
  stage,
  merchant,
  user,
  storeId,
  homePath,
  appName,
  merchantId,
  signOut,
  setCurrentStore,
}: {
  stage: string;
  merchant: string;
  storeId: string;
  user?: Employee;
  homePath: string;
  appName: string;
  merchantId: string;
  signOut: () => Promise<void>;
  setCurrentStore: (value: any) => void;
}) => {
  const [isLoading, setIsLoading] = useState(false);

  return (
    <HStack
      as={'header'}
      position={'sticky'}
      top={0}
      zIndex={1000}
      bg={useColorModeValue('white', 'gray.800')}
      boxShadow={'sm'}
      py={1}
      px={{
        base: 4,
        md: 8,
      }}>
      <Flex alignItems="center" gap="3">
        <Box borderRight="1px solid #ddd" paddingRight="2">
          <MenuTrigger
            stage={stage}
            applicationName={merchant}
            storeId={storeId}
            merchantId={merchantId}
          />
        </Box>
        <Text fontWeight="bold" fontSize="xl">
          <Link to={homePath}>{appName}</Link>
        </Text>
      </Flex>
      <Spacer />
      {user ? (
        <IconButton
          onClick={async () => {
            setIsLoading(true);
            await signOut();
            setCurrentStore(null);
            setIsLoading(false);
          }}
          variant="outline"
          isLoading={isLoading}
          colorScheme="blue"
          aria-label="Log Out"
          borderWidth={'0px'}
          fontSize="30px"
          icon={<VscSignOut />}
        />
      ) : (
        <IconButton
          onClick={() => {
            // Redirect to auth app

            window.location.href = `https://authenticator.${stage}.shopnsmile.org`;
          }}
          variant="outline"
          colorScheme="blue"
          borderWidth={'0px'}
          aria-label="Log Out"
          fontSize="30px"
          icon={<VscSignIn />}
        />
      )}
    </HStack>
  );
};
