import {
  Alert,
  AlertDescription,
  AlertIcon,
  AlertTitle,
  Center,
  Flex,
} from '@chakra-ui/react';

interface Props {
  description?: string;
}

const Error = ({ description }: Props) => {
  return (
    <Center my={3} width={'full'} height={'full'}>
      <Flex justify={'center'} align={'center'} gap={2}>
        <Alert status="error">
          <AlertIcon />
          <AlertTitle>Error Occured!</AlertTitle>
          <AlertDescription>{description}</AlertDescription>
        </Alert>
      </Flex>
    </Center>
  );
};

export default Error;
