// EditProduct.tsx

import {
  colors,
  EntityTypes,
  Image,
  Product,
  StoreProduct,
  StoreProductFraction,
  Tax,
  UpdateProduct,
  UpdateStoreProduct,
} from '@bofrak-backend/shared';
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  Checkbox,
  Flex,
  Grid,
  IconButton,
  Input,
  Text,
  useDisclosure,
  useToast,
} from '@chakra-ui/react';
import React, { useEffect, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { FaSave } from 'react-icons/fa';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useRecoilValue } from 'recoil';
import { apiAdapter } from '../../../api';
import {
  adminAtom,
  merchantAtom,
  selectedStoreAtom,
  storeProductsAtom,
} from '../../../recoil/atoms';
import ModalComponent from '../../resuable/ModalComponent';
import FractionForm from './FractionForm';
import ImageUpload from './ImageUpload';
import ProductDetailsCardForm from './ProductDetailForm';
import SubProductDetailsCardForm from './SubEditForm';

interface EditProductProps {
  selectedProduct: StoreProduct;
}

interface FormData {
  name: string;
  is_available_for_sale: boolean;
  is_sold_online: boolean;
  weight_kg: number;
  low_stock_threshold: number;
  taxes: string[]; // Array of tax IDs
}

const EditProduct: React.FC<EditProductProps> = ({ selectedProduct }) => {
  const toast = useToast();
  const queryClient = useQueryClient();
  const currentStore = useRecoilValue(selectedStoreAtom);
  const employee = useRecoilValue(adminAtom);
  const merchant = useRecoilValue(merchantAtom);
  const storeProducts = useRecoilValue(storeProductsAtom);
  const { isOpen, onOpen, onClose } = useDisclosure();

  const {
    register,
    handleSubmit,
    control,
    watch,
    setValue,
    formState: { dirtyFields },
  } = useForm<FormData>({
    defaultValues: {
      name: '',
      is_available_for_sale: false,
      is_sold_online: false,
      weight_kg: 0,
      low_stock_threshold: 0,
      taxes: [],
    },
  });

  // Memoize currentItem to prevent unnecessary re-renders
  const currentItem = useMemo(() => {
    return storeProducts?.find((product) => product.id === selectedProduct?.id);
  }, [storeProducts, selectedProduct]);

  // Update form values when currentItem changes
  useEffect(() => {
    if (currentItem) {
      setValue('name', currentItem.name || '');
      setValue(
        'is_available_for_sale',
        currentItem.is_available_for_sale || false,
      );
      setValue('is_sold_online', currentItem.is_sold_online || false);
      setValue('weight_kg', currentItem.weight_kg || 0);
      setValue('low_stock_threshold', currentItem.low_stock_threshold || 0);

      // Normalize taxes to an array of IDs
      const currentTaxesIds = Array.isArray(currentItem.taxes)
        ? currentItem.taxes.map((tax) =>
            typeof tax === 'string' ? tax : tax.id,
          )
        : [];
      setValue('taxes', currentTaxesIds);
    }
  }, [currentItem, setValue]);

  // Fetch taxes using useQuery
  const { data: taxesData } = useQuery(
    ['taxes', merchant?.id],
    () => apiAdapter.getTaxes({ merchant_id: merchant?.id as string }),
    {
      enabled: !!merchant?.id,
    },
  );
  const taxes = taxesData?.taxes || [];

  // Common success and error handlers for mutations
  const handleSuccess = (data: Product) => {
    if (!data?.id) return;
    queryClient.invalidateQueries(`get-product-${data.id}`);
    queryClient.invalidateQueries('store-products');
    toast({
      title: 'Product Updated',
      status: 'success',
      duration: 3000,
      isClosable: true,
    });
  };

  const handleError = (error: Error) => {
    toast({
      title: 'Error Updating Product',
      description: `${error.message}`,
      status: 'error',
      duration: 3000,
      isClosable: true,
    });
  };

  // Mutations for updating product name and store product details
  const { mutateAsync, mutate, isLoading } = useMutation(
    (data: UpdateProduct) => apiAdapter.updateProduct(data),
    {
      onSuccess: handleSuccess,
      onError: handleError,
    },
  );

  const updateStoreProductMutation = useMutation(
    (data: UpdateStoreProduct) =>
      apiAdapter.updateStoreProduct({
        merchant_id: merchant?.id as string,
        store_id: currentStore?.id as string,
        updateStoreProductDto: data,
      }),
    {
      onSuccess: handleSuccess,
      onError: handleError,
    },
  );

  // Function to update individual fields
  const updateField = (
    fieldName: keyof FormData,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    value: any,
    dirty: boolean,
  ) => {
    if (!currentItem) return;

    if (!dirty) {
      toast({
        title: 'No Changes Made',
        status: 'info',
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    const updatedData = {
      id: currentItem.id,
      store_id: currentStore?.id as string,
      merchant_id: merchant?.id as string,
      updator_id: employee?.id as string,
      [fieldName]: value,
    };

    if (fieldName === 'name') {
      const updateProductData: UpdateProduct = {
        id: currentItem.id,
        merchant_id: merchant?.id as string,
        updator_id: employee?.id as string,
        name: value,
      };
      mutate(updateProductData);
    } else {
      // Special handling for number fields
      if (fieldName === 'weight_kg' || fieldName === 'low_stock_threshold') {
        updatedData[fieldName] = Number(value) || 0;
      }
      mutate(updatedData as UpdateStoreProduct);
    }
  };

  // Handle image upload
  const handleImageUpload = async (image: Image) => {
    if (!currentItem) return;
    await mutateAsync({
      id: currentItem.id,
      merchant_id: merchant?.id as string,
      image: image.image_url,
      updator_id: employee?.id as string,
    });
  };

  return (
    <Box as="form" onSubmit={handleSubmit(() => {})}>
      <Flex justify="center" align="center" mb={4}>
        {/* Image Upload */}
        <ImageUpload
          entity_id={currentItem?.id as string}
          entity_type={EntityTypes.PRODUCT}
          merchant_id={merchant?.id as string}
          currentImage={currentItem?.image as string}
          onImageUpload={handleImageUpload}
        />
      </Flex>

      {/* Product Name */}
      <Flex justify="space-between" align="center">
        <Text color="gray.500" fontSize="sm" width="150px">
          Product Name
        </Text>
        <Input
          {...register('name')}
          borderRadius={0}
          _focus={{ boxShadow: 'none' }}
          placeholder="Product Name"
          borderRight={0}
          borderLeft={0}
          borderTop={0}
        />
        <IconButton
          onClick={() => updateField('name', watch('name'), !!dirtyFields.name)}
          isLoading={isLoading}
          isDisabled={isLoading || !dirtyFields.name}
          aria-label="Save"
          icon={<FaSave />}
        />
      </Flex>

      {/* Accordion for additional details */}
      <Accordion width={'full'} defaultIndex={[0]} p={0} allowMultiple>
        {/* Main Product Details */}
        <AccordionItem borderWidth={0} width={'full'}>
          <h2>
            <AccordionButton
              borderWidth={'1px'}
              borderColor={colors.primary}
              borderRadius="full"
              bg={'blue.50'}
              mt={2}>
              <Flex
                justify="space-between"
                align="center"
                width="100%"
                color="black">
                <Flex justifyContent="flex-start" align="center">
                  <Text fontSize="sm" fontWeight="bold">
                    {`${currentItem?.name} (${currentItem?.unit})`}
                  </Text>
                </Flex>
                <AccordionIcon color="black" />
              </Flex>
            </AccordionButton>
          </h2>
          <AccordionPanel p={0}>
            <ProductDetailsCardForm item={currentItem as StoreProduct} />
          </AccordionPanel>
        </AccordionItem>

        {/* Sub Items */}
        {currentItem?.store_product_fractions?.map(
          (fraction: StoreProductFraction, index: number) => (
            <AccordionItem borderWidth={0} key={index}>
              <h2>
                <AccordionButton
                  bg={'blue.50'}
                  borderWidth={'1px'}
                  borderColor={colors.primary}
                  borderRadius="full"
                  mt={2}>
                  <Flex justify="space-between" align="center" width="100%">
                    <Flex justifyContent="flex-start" align="center">
                      <Text fontSize="sm" fontWeight="bold">
                        {`${fraction.name} (${fraction.unit})`}
                      </Text>
                    </Flex>
                    <AccordionIcon color="black" />
                  </Flex>
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <SubProductDetailsCardForm item={fraction} />
              </AccordionPanel>
            </AccordionItem>
          ),
        )}

        {/* Add Sub Item Button */}
        <Flex justify="center" align="center" width="100%">
          <Button
            onClick={() => {
              if (!currentItem) {
                toast({
                  title: 'Choose a product first',
                  status: 'error',
                  duration: 3000,
                  isClosable: true,
                });
              } else {
                onOpen();
              }
            }}
            size="sm"
            m={2}
            border={0}
            borderRadius={'full'}
            bg={colors.primary}
            color="white"
            width="50%"
            variant="none">
            Add Sub Item
          </Button>
        </Flex>

        {/* General Settings */}
        <AccordionItem>
          <h2>
            <AccordionButton>
              <Box
                as="span"
                flex="1"
                textAlign="left"
                color="blue.500"
                fontWeight="bold">
                General Settings{' '}
                <Text fontWeight={'thin'} color="gray.500" fontSize="xs">
                  Applies to all stores
                </Text>
              </Box>

              <AccordionIcon color="blue" />
            </AccordionButton>
          </h2>
          <AccordionPanel pb={4}>
            <Box>
              {/* Taxes */}
              <Flex
                align="start"
                justify="start"
                width="full"
                direction="column"
                my={3}
                p={2}
                borderRadius="5px">
                <Text mb={2}>Tax</Text>
                <Grid
                  width="full"
                  justifyItems="center"
                  alignItems="center"
                  gap={2}
                  templateColumns={{
                    base: 'repeat(2, 1fr)',
                    sm: 'repeat(3, 1fr)',
                    md: 'repeat(4, 1fr)',
                    lg: 'repeat(5, 1fr)',
                  }}>
                  {taxes?.map((tax: Tax, index: number) => (
                    <Flex
                      key={index}
                      justify="center"
                      align="center"
                      gap={2}
                      direction="column">
                      <Controller
                        name="taxes"
                        control={control}
                        render={({ field }) => (
                          <Checkbox
                            isChecked={field.value.includes(tax.id)}
                            onChange={(e) => {
                              const isChecked = e.target.checked;
                              const newValue = isChecked
                                ? [...field.value, tax.id]
                                : field.value.filter((id) => id !== tax.id);
                              field.onChange(newValue);
                            }}
                          />
                        )}
                      />
                      <Text
                        color="gray.500"
                        fontSize="xs"
                        width="100%"
                        textAlign="center">
                        {tax.name} ({tax.rate}%)
                      </Text>
                    </Flex>
                  ))}
                </Grid>

                <Flex justify="flex-start" align="start" mt={4}>
                  <Button
                    colorScheme="blue"
                    size="sm"
                    isLoading={updateStoreProductMutation.isLoading}
                    isDisabled={isLoading || !dirtyFields.taxes}
                    onClick={() =>
                      updateField('taxes', watch('taxes'), !!dirtyFields.taxes)
                    }>
                    Save
                  </Button>
                </Flex>
              </Flex>

              {/* Weight */}
              <Flex
                justify="space-between"
                align="center"
                my={3}
                p={2}
                bg="white"
                borderRadius="5px">
                <Text color="gray.500" fontSize="sm" width="150px">
                  Weight (main item)
                </Text>
                <Input
                  {...register('weight_kg')}
                  borderRadius={0}
                  _focus={{ boxShadow: 'none' }}
                  placeholder="Weight"
                  borderRight={0}
                  borderLeft={0}
                  borderTop={0}
                />
                <IconButton
                  onClick={() =>
                    updateField(
                      'weight_kg',
                      watch('weight_kg'),
                      !!dirtyFields.weight_kg,
                    )
                  }
                  isLoading={updateStoreProductMutation.isLoading}
                  isDisabled={isLoading || !dirtyFields.weight_kg}
                  aria-label="Save"
                  icon={<FaSave />}
                />
              </Flex>

              {/* Low Stock Threshold */}
              <Flex
                justify="space-between"
                align="center"
                my={3}
                p={2}
                bg="white"
                borderRadius="5px">
                <Text color="gray.500" fontSize="sm" width="150px">
                  Low Stock
                </Text>
                <Input
                  {...register('low_stock_threshold')}
                  borderRadius={0}
                  _focus={{ boxShadow: 'none' }}
                  placeholder="Low Stock Threshold"
                  borderRight={0}
                  borderLeft={0}
                  borderTop={0}
                />
                <IconButton
                  onClick={() =>
                    updateField(
                      'low_stock_threshold',
                      watch('low_stock_threshold'),
                      !!dirtyFields.low_stock_threshold,
                    )
                  }
                  isLoading={updateStoreProductMutation.isLoading}
                  isDisabled={isLoading || !dirtyFields.low_stock_threshold}
                  aria-label="Save"
                  icon={<FaSave />}
                />
              </Flex>
            </Box>
          </AccordionPanel>
        </AccordionItem>
      </Accordion>

      {/* Modal for Adding Sub Item */}
      {currentItem && (
        <ModalComponent isOpen={isOpen} onClose={onClose} size="md">
          <Text textAlign="center" fontWeight="bold" fontSize="lg">
            Create Sub Item
          </Text>
          <FractionForm item={currentItem} closeForm={onClose} />
        </ModalComponent>
      )}
    </Box>
  );
};

export default EditProduct;
