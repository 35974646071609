import { useEffect, useState } from 'react';
import { Employee, EncryptCredentialsDto, Store } from '@bofrak-backend/shared';
import { apiAdapter } from '../utils/api';

interface UseAuthReturn {
  decriptedCredentials: EncryptCredentialsDto | null;
  encryptedCredentials: string | null;
  errorMessage: string | null;
  isLoading: boolean;
  employee: Employee | null;
  signOut: () => Promise<void>;
  currentStore: Store | null;
}

export const useAuth = ({
  stage,
}: {
  stage: string;
  applicationName: string;
}): UseAuthReturn => {
  const [decriptedCredentials, setDecryptedCredentials] =
    useState<EncryptCredentialsDto | null>(null);
  const [encryptedCredentials, setEncryptedCredentials] = useState<
    string | null
  >(null);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [employee, setEmployee] = useState<Employee | null>(null);
  const [currentStore, setCurrentStore] = useState<Store | null>(null);
  const [storeId, setStoreId] = useState<string | null>(null);

  const signOut = async () => {
    setIsLoading(true);

    if (decriptedCredentials) {
      const { AccessToken } = decriptedCredentials;

      await apiAdapter.signOutUser({
        accessToken: AccessToken,
      });
    } else if (encryptedCredentials) {
      const { AccessToken } = await apiAdapter.decryptUserData({
        encryptedCredentials,
      });

      await apiAdapter.signOutUser({
        accessToken: AccessToken,
      });
    } else {
      const encryptedCredentials = localStorage.getItem('encryptedCredentials');

      if (encryptedCredentials) {
        const { AccessToken } = await apiAdapter.decryptUserData({
          encryptedCredentials,
        });

        await apiAdapter.signOutUser({
          accessToken: AccessToken,
        });
      }
    }

    setEncryptedCredentials(null);
    setDecryptedCredentials(null);
    setEmployee(null);
    setErrorMessage(null);
    localStorage.clear();

    setIsLoading(false);

    // Redirect to the authenticator app with a sign out query param
    const authenticatorUrl = `https://authenticator.${stage}.shopnsmile.org`;

    window.location.href = `${authenticatorUrl}?signOut=true`;
  };

  useEffect(() => {
    const fetchEncryptedCredentials = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      let encrypted = urlParams.get('token')
        ? decodeURIComponent(urlParams.get('token')!)
        : null;

      if (!encrypted) {
        encrypted = localStorage.getItem('encryptedCredentials');
      }

      if (encrypted) {
        setEncryptedCredentials(encrypted);
        try {
          const decrypted = await apiAdapter.decryptUserData({
            encryptedCredentials: encrypted,
          });
          setDecryptedCredentials(decrypted);

          const { Employee } = decrypted;

          setEmployee(Employee);

          localStorage.setItem('encryptedCredentials', encrypted);
        } catch (error) {
          setErrorMessage('Invalid session');
        }
      } else {
        await signOut();
      }

      const store_id = urlParams.get('store_id');

      setStoreId(store_id);

      setIsLoading(false);
    };

    fetchEncryptedCredentials();
  }, []);

  useEffect(() => {
    async function fetchStore() {
      if (employee && storeId) {
        const store = await apiAdapter.getStore(storeId, employee.merchant_id);
        setCurrentStore(store);
      }
    }
    fetchStore();
  }, [storeId, employee]);

  return {
    decriptedCredentials,
    encryptedCredentials,
    errorMessage,
    isLoading,
    signOut,
    employee,
    currentStore,
  };
};
