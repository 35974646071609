import { Product, colors, defaultImage } from '@bofrak-backend/shared';
import {
  Box,
  Button,
  Flex,
  IconButton,
  Image,
  Stack,
  Text,
  useDisclosure,
} from '@chakra-ui/react';
import Fuse from 'fuse.js';
import { useEffect, useState, useMemo } from 'react';
import { FaAngleLeft, FaRegClone } from 'react-icons/fa';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { apiAdapter } from '../../../api';
import { merchantAtom, selectedStoreAtom } from '../../../recoil/atoms';
import Loader from '../../resuable/Loader';
import ModalComponent from '../../resuable/ModalComponent';
import SearchBarWithAddButton from '../../search-products';
import CreateImportProduct from './CreateImportedProduct';
import CreateProductPage from './NewProduct';

const ProductListPage = () => {
  const navigate = useNavigate();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const merchant = useRecoilValue(merchantAtom);
  const store = useRecoilValue(selectedStoreAtom);
  const [selectedProduct, setSelectedProduct] = useState<Product | null>(null);
  const {
    isOpen: isCreateModalOpen,
    onOpen: onOpenCreateModal,
    onClose: onCloseCreateModal,
  } = useDisclosure();

  const handleOpenModal = () => {
    onOpen();
  };

  // Fetch all merchant products
  const fetchAllMerchantProducts = async (merchantId: string) => {
    let products: Product[] = [];
    let cursor: string | undefined = undefined;
    const limit = 250;

    do {
      const response = await apiAdapter.getProducts({
        merchant_id: merchantId,
        limit,
        cursor,
      });

      products = products.concat(response.products);
      cursor = response.cursor;
    } while (cursor);

    return products;
  };

  // Fetch all store products
  const fetchAllStoreProducts = async (merchantId: string, storeId: string) => {
    let products: Product[] = [];
    let cursor: string | undefined = undefined;
    const limit = 250;

    do {
      const response = await apiAdapter.getStoreProducts({
        merchant_id: merchantId,
        store_id: storeId,
        limit,
        cursor,
      });

      products = products.concat(response.products);
      cursor = response.cursor;
    } while (cursor);

    return products;
  };

  // Use useQuery to fetch merchant products
  const { data: merchantProducts, isLoading: isMerchantLoading } = useQuery(
    ['merchantProducts', merchant?.id],
    () => fetchAllMerchantProducts(merchant?.id as string),
    {
      enabled: !!merchant?.id,
      staleTime: 5 * 60 * 1000, // Cache data for 5 minutes
    },
  );

  // Use useQuery to fetch store products
  const { data: storeProducts, isLoading: isStoreLoading } = useQuery(
    ['storeProducts', merchant?.id, store?.id],
    () => fetchAllStoreProducts(merchant?.id as string, store?.id as string),
    {
      enabled: !!merchant?.id && !!store?.id,
      staleTime: 5 * 60 * 1000, // Cache data for 5 minutes
    },
  );

  // Compute products not in store using useMemo to avoid unnecessary re-renders
  const productsNotInStore = useMemo(() => {
    if (
      !isMerchantLoading &&
      !isStoreLoading &&
      merchantProducts &&
      storeProducts
    ) {
      const storeProductIds = new Set(storeProducts.map((p) => p.id));
      return merchantProducts.filter(
        (product) => !storeProductIds.has(product.id),
      );
    }
    return [];
  }, [merchantProducts, storeProducts, isMerchantLoading, isStoreLoading]);

  const [filteredProducts, setFilteredProducts] = useState<Product[]>([]);

  useEffect(() => {
    setFilteredProducts(productsNotInStore);
  }, [productsNotInStore]);

  const addSelectProduct = (product: Product) => {
    onOpenCreateModal();
    if (product?.id) {
      setSelectedProduct(product);
    }
  };

  // Set up Fuse.js search options using useMemo
  const fuse = useMemo(
    () =>
      new Fuse(productsNotInStore, {
        keys: ['name', 'description'],
        threshold: 0.3,
      }),
    [productsNotInStore],
  );

  // Function to handle search
  const handleSearch = (query: string) => {
    if (query.trim() === '') {
      setFilteredProducts(productsNotInStore);
    } else {
      const result = fuse.search(query).map(({ item }) => item);
      setFilteredProducts(result);
    }
  };

  return (
    <Box bg="white" p={4} maxW="full" mx="auto">
      <Flex justify="start" align="center" mb={4}>
        <IconButton
          aria-label="Back"
          icon={<FaAngleLeft />}
          variant="outline"
          colorScheme="blackAlpha"
          size="md"
          onClick={() => navigate(-1)}
        />
      </Flex>
      {/* Buttons */}
      <Flex justify="space-between" mb={4} gap={2}>
        <Button
          bg={colors.primary}
          color="white"
          borderRadius="full"
          onClick={handleOpenModal}>
          + Create New Product
        </Button>
        <Button
          bg={colors.primary}
          color="white"
          borderRadius="full"
          onClick={onOpenCreateModal}>
          Import Bulk Products
        </Button>
      </Flex>

      {isMerchantLoading || isStoreLoading ? (
        <Loader title="Loading" />
      ) : (
        <Box>
          {/* Search Bar */}
          <Box my={3}>
            <SearchBarWithAddButton
              onSearch={handleSearch}
              showAddButton={false}
              borderRadius="full"
            />
          </Box>
          {/* Product List or Message */}
          {filteredProducts.length === 0 ? (
            // Display message when the list is empty
            <Box textAlign="center" mt={10}>
              <Text fontSize="lg" color="gray.500">
                There are no products to import. All products are already in the
                store.
              </Text>
            </Box>
          ) : (
            // Display product list when there are products
            <Stack spacing={4} width="full" overflowY="auto" height="100vh">
              {filteredProducts.map((product) => (
                <Flex
                  key={product.id}
                  borderWidth="0.5px"
                  borderRadius="full"
                  borderColor={colors.primary}
                  boxShadow="md"
                  px={5}
                  py={1}
                  align="center"
                  justify="space-between">
                  {/* Product Image */}
                  <Flex align="center">
                    <Image
                      src={product?.image}
                      alt="Product Image"
                      boxSize="50px"
                      borderRadius="md"
                      mr={3}
                      onError={(e) => {
                        e.currentTarget.src = defaultImage;
                      }}
                    />
                    {/* Product Info */}
                    <Box>
                      <Text fontWeight="bold">{product?.name}</Text>
                      <Text color="gray.400" fontSize="xs">
                        {product?.description}
                      </Text>
                    </Box>
                  </Flex>

                  {/* Action Icon */}
                  <IconButton
                    borderRadius="full"
                    borderColor={colors.primary}
                    p="10px"
                    aria-label="Duplicate Product"
                    icon={<FaRegClone />}
                    variant="outline"
                    borderWidth="0.5px"
                    color={colors.primary}
                    size="md"
                    onClick={() => addSelectProduct(product)}
                  />
                </Flex>
              ))}
            </Stack>
          )}
        </Box>
      )}
      <ModalComponent isOpen={isOpen} onClose={onClose} size="md">
        <CreateProductPage onClose={onClose} />
      </ModalComponent>

      <ModalComponent
        isOpen={isCreateModalOpen}
        onClose={onCloseCreateModal}
        size="md">
        <CreateImportProduct
          selectedProduct={selectedProduct as Product}
          onCloseCreateModal={onCloseCreateModal}
        />
      </ModalComponent>
    </Box>
  );
};

export default ProductListPage;
