import { colors } from '@bofrak-backend/shared';
import { Center, Flex, Spinner, Text } from '@chakra-ui/react';

interface Props {
  title?: string;
}

const Loader = ({ title = 'Loading' }: Props) => {
  return (
    <Center my={3} width={'full'} height={'full'}>
      <Flex justify={'center'} align={'center'} gap={2}>
        <Spinner size={'sm'} />
        {title && (
          <Text color={colors.primary} fontSize={'md'}>
            {title}
          </Text>
        )}
      </Flex>
    </Center>
  );
};

export default Loader;
