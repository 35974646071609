import { colors } from '@bofrak-backend/shared';
import {
  Box,
  IconButton,
  Input,
  InputGroup,
  InputRightElement,
} from '@chakra-ui/react';
import { useState } from 'react';
import { FaPlus, FaSearch } from 'react-icons/fa';
import { useNavigate } from 'react-router-dom';
import { routes } from '../../constants/routes';

const SearchBarWithAddBut: React.FC<{
  onSearch: (query: string) => void;
  showAddButton?: boolean;
  borderRadius?: string;
}> = ({ onSearch, showAddButton = true, borderRadius = 'full' }) => {
  const [searchTerm, setSearchTerm] = useState('');
  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setSearchTerm(value);
    onSearch(value); // Pass the search term back to the parent
  };

  return (
    <Box display="flex" alignItems="center" gap={4}>
      {/* Search Input with Search Icon */}
      <InputGroup>
        <Input
          placeholder="Search for product"
          borderRadius={borderRadius}
          borderColor={colors.primary}
          _focus={{
            borderColor: 'blue.400',
            boxShadow: 'none',
          }}
          value={searchTerm}
          onChange={handleChange}
        />
        {showAddButton && (
          <InputRightElement>
            <IconButton
              aria-label="Search"
              icon={<FaSearch />}
              variant="ghost"
              color="gray.500"
            />
          </InputRightElement>
        )}
      </InputGroup>

      {/* Add Button */}
      {showAddButton && (
        <IconButton
          aria-label="Add"
          icon={<FaPlus />}
          bg={colors.primary}
          color="white"
          onClick={() => navigate(routes.newProduct)}
          borderRadius="full"
          _hover={{ bg: 'blue.400' }}
        />
      )}
    </Box>
  );
};

export default SearchBarWithAddBut;
