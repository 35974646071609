export const category = {
  items: '/items',
  inventory_count: '/inventory_counts',
  clients_credits: '/clients_credits',
  cash_flow: '/cash_flow',
  store_credits: '/store_credits',
  purchase: '/purchase',
  warehouse: '/warehouse',
} as const;

export const generateCategoriesAsRoutes = () => {
  return Object.entries(category).map(([key, route]) => ({
    route,
    title: key.replace(/_/g, ' ').replace(/\b\w/g, (c) => c.toUpperCase()), // Convert underscore to space and capitalize words
  }));
};

export const categories = generateCategoriesAsRoutes();
