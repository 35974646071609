import { InventoryChangeReason, UserRole } from '@bofrak-backend/shared';
import { CheckedReasons } from './types';
import { QueryClient } from 'react-query';
export const queryClient = new QueryClient();

export function unStringfy(str: any) {
  try {
    return JSON.parse(str);
  } catch (e) {
    return str;
  }
}

export function camelCaseToSentence(camelCase: string) {
  return camelCase.replace(/([A-Z])/g, ' $1').replace(/^./, function (str) {
    return str.toUpperCase();
  });
}

export function enumToArray(enumObj: any): string[] {
  return Object.keys(enumObj)
    .filter((key) => isNaN(Number(key)))
    .map((key) => enumObj[key])
    .map((key) => enumObj[key]);
}

export function formatDate(date: Date) {
  const day = date.getDate().toString().padStart(2, '0');
  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const year = date.getFullYear().toString().substr(2, 2);
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  return `${day}/${month}/${year} ${hours}:${minutes}`;
}

export const acceptedRoles = [UserRole.ADMIN, UserRole.MANAGER];

export const computeSelectedFetchReason = (
  checkedReasons: CheckedReasons,
): InventoryChangeReason[] => {
  const selectedReasons = new Set<InventoryChangeReason>();

  for (const reason of Object.keys(checkedReasons)) {
    const reasonKey = reason as keyof CheckedReasons;

    if (checkedReasons[reasonKey]) {
      switch (reasonKey) {
        case 'correction':
          selectedReasons.add(InventoryChangeReason.INVENTORY_CORRECTION);
          break;
        case 'purchase':
          selectedReasons.add(InventoryChangeReason.PURCHASE_ORDER);
          break;
        case 'count':
          selectedReasons.add(InventoryChangeReason.INVENTORY_COUNT);
          break;
        case 'sale':
          selectedReasons.add(InventoryChangeReason.SALE);
          break;
        case 'refund':
          selectedReasons.add(InventoryChangeReason.REFUND);
          break;
        default:
          // Optionally handle unexpected keys
          console.warn(`Unhandled reason key: ${reasonKey}`);
          break;
      }
    }
  }

  return Array.from(selectedReasons);
};
