// Import statements
import {
  formatCurrency,
  InventoryChange,
  InventoryChangeReason,
} from '@bofrak-backend/shared';
import {
  Alert,
  AlertIcon,
  Box,
  chakra,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Thead,
  Tr,
} from '@chakra-ui/react';
import React from 'react';

// Custom table header component
const CustomTh = chakra('th', {
  baseStyle: {
    borderBottom: '1px solid',
    borderColor: 'gray.300',
  },
});

// Date formatting function
function formatDate(created_at: string) {
  const date = new Date(created_at);

  const day = String(date.getDate()).padStart(2, '0'); // DD
  const month = String(date.getMonth() + 1).padStart(2, '0'); // MM
  const year = date.getFullYear(); // YYYY

  const hours = String(date.getHours()).padStart(2, '0'); // HH
  const minutes = String(date.getMinutes()).padStart(2, '0'); // mm

  return `${day}/${month}/${year} ${hours}:${minutes}`;
}

// TableRow component
const TableRow: React.FC<InventoryChange> = ({
  change_quantity: adjustment,
  created_at,
  cumulative_quantity: after_stock,
  reason,
  cost,
  source_name,
  source_id,
}) => {
  const cost_difference = adjustment * cost;

  return (
    <Tr fontSize={'sm'}>
      {/* Date and User */}
      <Td>
        <Box>
          <Text fontWeight={'bold'} fontSize={'sm'}>
            {formatDate(created_at)}
          </Text>
          <Text pt={1} fontSize={'xs'}>
            {source_name ?? 'UNKNOWN'}
          </Text>
        </Box>
      </Td>

      {/* Adjustment, After Stock, Cost Difference */}
      <Td>
        <Box position="relative" minW="100px" h="50px">
          <Text
            textTransform={'lowercase'}
            fontSize="xs"
            position="absolute"
            left="30%"
            top="0"
            color={adjustment > 0 ? 'green.500' : 'red.500'}>
            {adjustment > 0 ? '+' : ''}
            {adjustment.toFixed(2)}
          </Text>
          <Text
            fontWeight={'bold'}
            position="absolute"
            left="50%"
            top="50%"
            transform="translate(-50%, -50%)">
            {after_stock.toFixed(2)}
          </Text>
          <Text
            fontSize="xs"
            position="absolute"
            right="30%"
            bottom="0"
            color={cost_difference > 0 ? 'green.500' : 'red.500'}>
            {formatCurrency(cost_difference, false)}
          </Text>
        </Box>
      </Td>

      {/* Reason */}
      <Td>
        <Text fontWeight={'bold'} textAlign={'center'} fontSize={'xs'}>
          {reason.replace(/Inventory/g, '').toUpperCase()}
        </Text>
        {[
          InventoryChangeReason.SALE,
          InventoryChangeReason.REFUND,
          InventoryChangeReason.PURCHASE_ORDER,
        ].includes(reason) && (
          <Text
            fontWeight={'bold'}
            color={'blue'}
            textAlign={'center'}
            fontSize={'xx-small'}>
            {source_id.toUpperCase()}
          </Text>
        )}
      </Td>
    </Tr>
  );
};

// InventoryHistoryTable component
export const InventoryHistoryTable: React.FC<{
  data: InventoryChange[];
}> = ({ data }) => {
  return data.length ? (
    <TableContainer width="full">
      <Table variant="simple" size="sm">
        <Thead>
          <Tr fontSize={'sm'}>
            <CustomTh>Date/User</CustomTh>
            <CustomTh>
              <Box position="relative" p={2} minW="100px" h="50px">
                <Text
                  textTransform={'lowercase'}
                  fontSize="xs"
                  position="absolute"
                  left="0"
                  top="0">
                  adj
                </Text>
                <Text
                  position="absolute"
                  left="50%"
                  top="50%"
                  transform="translate(-50%, -50%)">
                  After Stock
                </Text>
                <Text
                  textTransform={'lowercase'}
                  fontSize="xs"
                  position="absolute"
                  right="0"
                  bottom="0">
                  cost diff
                </Text>
              </Box>
            </CustomTh>
            <CustomTh>Reason</CustomTh>
          </Tr>
        </Thead>
        <Tbody>
          {data.map((row) => (
            <TableRow key={row.id} {...row} />
          ))}
        </Tbody>
      </Table>
    </TableContainer>
  ) : (
    <Alert textTransform={'capitalize'} width={'auto'} status="info">
      <AlertIcon />
      No History Found
    </Alert>
  );
};
