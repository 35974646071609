/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  CreateImage,
  CreateProduct,
  CreateStoreProduct,
  EntityTypes,
  StoreProduct,
  Tax,
} from '@bofrak-backend/shared';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormLabel,
  Grid,
  Input,
  Switch,
  Text,
  useToast,
} from '@chakra-ui/react';
import { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useMutation, useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { apiAdapter } from '../../../api';
import {
  adminAtom,
  merchantAtom,
  selectedStoreAtom,
} from '../../../recoil/atoms';
import UploadImage from './ImageUpload';

interface Props {
  onClose: () => void;
}

const CreateProductPage = ({ onClose }: Props) => {
  const merchant = useRecoilValue(merchantAtom);
  const navigate = useNavigate();
  const [isWeighted, setIsWeighted] = useState(false);
  const [isForSale, setIsForSale] = useState(false);
  const [isOnline, setIsOnline] = useState(false);
  const employee = useRecoilValue(adminAtom);
  const currentStore = useRecoilValue(selectedStoreAtom);
  const [taxes, setTaxes] = useState<any[]>([]);
  const toast = useToast();
  const [selectedTaxes, setSelectedTaxes] = useState<string[]>([]);
  const [createdProduct, setCreatedProduct] = useState<StoreProduct | null>(
    null,
  );

  const {
    register,
    handleSubmit,
    getValues,
    control,
    watch,
    formState: { errors },
  } = useForm();

  const {
    isLoading: isCreatingStoreProduct,
    mutateAsync: createStoreProductMutation,
  } = useMutation(
    'create-store-product',
    (data: CreateStoreProduct) =>
      apiAdapter.createStoreProduct({
        createStoreProductDto: data,
        merchant_id: merchant?.id as string,
      }),
    {
      onSuccess: (data) => {
        if (!data) return;

        onClose();
        toast({
          title: 'Store Product created successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });

        setCreatedProduct(data);
      },
      onError: () => {
        toast({
          title: 'Store Product creation failed',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      },
    },
  );

  const [base64Image, setBase64Image] = useState<string | null>(null);

  const { isLoading: isCreatingProduct, mutateAsync: createProductMutation } =
    useMutation((data: CreateProduct) => apiAdapter.createProduct(data), {
      onSuccess: async (data) => {
        if (!data?.id) return;

        if (!merchant?.id) {
          toast({
            title: 'Merchant ID not found',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
          return;
        }

        if (!employee?.id) {
          toast({
            title: 'Employee ID not found',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
          return;
        }

        if (!currentStore?.id) {
          toast({
            title: 'Store ID not found',
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
          return;
        }

        const dataObj: CreateStoreProduct = {
          product_id: data.id,
          store_id: currentStore?.id as string,
          inventory: 0,
          is_available_for_sale: isForSale,
          is_sold_by_range: false,
          max_selling_price: Number(watch('price')),
          min_selling_price: Number(watch('cost')),
          is_manually_controlled: false,
          is_sold_by_weight: isWeighted,
          is_sold_online: isOnline,
          loyalty_programs: [],
          price: Number(watch('price')),
          cost: Number(watch('cost')),
          low_stock_threshold: Number(watch('lowStock')),
          merchant_id: merchant?.id as string,
          creator_id: employee?.id as string,
        };

        try {
          await createStoreProductMutation(dataObj);
        } catch (err) {
          console.log(err);
        }

        // If there is an image, upload it
        if (base64Image) {
          const createImageData: CreateImage = {
            base64Image,
            entity_type: EntityTypes.PRODUCT,
            entity_id: data.id,
            merchant_id: merchant.id,
            image_id: '',
          };

          let imageUrl = '';

          try {
            const { image_url } = await apiAdapter.uploadImage(createImageData);
            imageUrl = image_url;
          } catch (err) {
            console.log(err);
            toast({
              title: 'Image upload failed',
              status: 'error',
              duration: 3000,
              isClosable: true,
            });
          }

          // Update the product with the image
          try {
            await apiAdapter.updateProduct({
              id: data.id,
              updator_id: employee?.id as string,
              merchant_id: merchant?.id as string,
              image: imageUrl,
            });
          } catch (err) {
            console.log(err);
            toast({
              title: 'Product update with image failed',
              status: 'error',
              duration: 3000,
              isClosable: true,
            });
          }
        }

        toast({
          title: 'Product created successfully',
          status: 'success',
          duration: 3000,
          isClosable: true,
        });
      },
      onError: (error: any) => {
        if (error?.response?.data?.message) {
          toast({
            title: 'Error creating Product',
            description: `${error?.response?.data?.message}`,
            status: 'error',
            duration: 3000,
            isClosable: true,
          });
          return;
        }
        toast({
          title: 'Product creation failed',
          status: 'error',
          duration: 3000,
          isClosable: true,
        });
      },
    });

  const onSubmit = async (data: any) => {
    // Get the image file and convert to Base64
    const imageFiles = getValues('image') as FileList;
    let base64ImageData: string | null = null;

    if (imageFiles && imageFiles[0]) {
      const file = imageFiles[0];
      base64ImageData = await new Promise<string>((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result as string);
        reader.onerror = (error) => reject(error);
      });
    }

    setBase64Image(base64ImageData);

    const dataObj: CreateProduct = {
      name: data.productName,
      description: 'NA',
      image: 'x',
      barcode: 0o0,
      quantity_in_unit_package: Number(data.itemsInUnit),
      weight_kg: Number(data.weight),
      unit: data.unit,
      creator_id: employee?.id as string,
      merchant_id: merchant?.id as string,
      taxes: selectedTaxes,
    };

    try {
      await createProductMutation(dataObj);
    } catch (err) {
      console.log(err);
    }
  };

  useQuery(
    `taxes-${merchant?.id}`,
    () =>
      apiAdapter.getTaxes({ merchant_id: merchant?.id as string, limit: 100 }),
    {
      onSuccess: (data) => {
        setTaxes(data?.taxes);
      },
    },
  );

  const handleTaxChange = (taxId: string, isChecked: boolean) => {
    setSelectedTaxes((prev) => {
      if (isChecked) {
        return [...prev, taxId];
      } else {
        return prev.filter((id) => id !== taxId);
      }
    });
  };

  return (
    <Box p={4} maxW="full" mx="auto" bg={'white'}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Flex justify={'space-between'} align={'center'} mb={4}>
          {/* Toggles */}
          <Box>
            <Flex justify="space-between" mb={4}>
              <FormLabel>Weighted</FormLabel>
              <Switch
                {...register('isWeighted')}
                isChecked={isWeighted}
                onChange={() => setIsWeighted(!isWeighted)}
              />
            </Flex>
            <Flex justify="space-between" mb={4}>
              <FormLabel>For Sale</FormLabel>
              <Switch
                {...register('isForSale')}
                isChecked={isForSale}
                onChange={() => setIsForSale(!isForSale)}
              />
            </Flex>
            <Flex justify="space-between" mb={4}>
              <FormLabel>Online Sale</FormLabel>
              <Switch
                {...register('isOnline')}
                isChecked={isOnline}
                onChange={() => setIsOnline(!isOnline)}
              />
            </Flex>
          </Box>

          {/* Camera Icon */}
          <UploadImage
            entity_id={createdProduct ? createdProduct.id : ''}
            entity_type={EntityTypes.PRODUCT}
            currentImage={''}
            merchant_id={merchant?.id as string}
            onImageUpload={async (image) => {
              await apiAdapter.updateProduct({
                id: image.entity_id,
                updator_id: employee?.id as string,
                merchant_id: merchant?.id as string,
                image: image.image_url,
              });

              navigate(`/`);
            }}
          />
        </Flex>

        {/* Input Fields */}
        <Input
          borderRight={0}
          borderLeft={0}
          borderTop={0}
          placeholder="Displayed name on the receipt"
          mb={4}
          isDisabled={true}
          bg={'blue.50'}
        />

        <Flex justify={'space-between'} align={'center'} mb={4}>
          <Text color={'gray.500'} fontSize={'sm'} width={'150px'}>
            Product Name
          </Text>
          <Input
            borderRadius={0}
            _focus={{
              boxShadow: 'none',
            }}
            borderRight={0}
            borderLeft={0}
            borderTop={0}
            placeholder="Product Name"
            {...register('productName', {
              required: 'Product Name is required',
            })}
          />
        </Flex>
        {errors.productName && (
          <Text color="red.500" fontSize="sm">
            {errors.productName.message as string}
          </Text>
        )}

        <Flex justify={'space-between'} align={'center'} mb={4}>
          <Text color={'gray.500'} fontSize={'sm'} width={'150px'}>
            Price
          </Text>
          <Input
            borderRadius={0}
            _focus={{
              boxShadow: 'none',
            }}
            placeholder="Price"
            {...register('price', {
              required: 'Price is required',
              pattern: {
                value: /^[0-9]+(\.[0-9]{1,2})?$/,
                message: 'Enter a valid price',
              },
            })}
          />
        </Flex>
        {errors.price && (
          <Text color="red.500" fontSize="sm">
            {errors.price.message as string}
          </Text>
        )}

        <Flex justify={'space-between'} align={'center'} mb={4}>
          <Text color={'gray.500'} fontSize={'sm'} width={'150px'}>
            Cost
          </Text>
          <Input
            borderRadius={0}
            _focus={{
              boxShadow: 'none',
            }}
            placeholder="Cost"
            {...register('cost', {
              required: 'Cost is required',
              pattern: {
                value: /^[0-9]+(\.[0-9]{1,2})?$/,
                message: 'Enter a valid cost',
              },
            })}
          />
        </Flex>
        {errors.cost && (
          <Text color="red.500" fontSize="sm">
            {errors.cost.message as string}
          </Text>
        )}

        <Flex justify={'space-between'} align={'center'} mb={4}>
          <Text color={'gray.500'} fontSize={'sm'} width={'150px'}>
            Unit
          </Text>
          <Input
            borderRadius={0}
            _focus={{
              boxShadow: 'none',
            }}
            placeholder="Unit"
            {...register('unit', { required: 'Unit is required' })}
          />
        </Flex>
        {errors.unit && (
          <Text color="red.500" fontSize="sm">
            {errors.unit.message as string}
          </Text>
        )}

        <Flex justify={'space-between'} align={'center'} mb={4}>
          <Text color={'gray.500'} fontSize={'sm'} width={'150px'}>
            Items in unit
          </Text>
          <Input
            borderRadius={0}
            _focus={{
              boxShadow: 'none',
            }}
            placeholder="Items in unit"
            {...register('itemsInUnit', {
              required: 'Items in unit are required',
              pattern: {
                value: /^[0-9]+$/,
                message: 'Enter a valid number',
              },
            })}
          />
        </Flex>
        {errors.itemsInUnit && (
          <Text color="red.500" fontSize="sm">
            {errors.itemsInUnit.message as string}
          </Text>
        )}

        {/* Tax Section */}
        <Flex
          align={'center'}
          justify={'space-between'}
          width={'full'}
          my={3}
          p={2}
          bg={'blue.50'}
          borderRadius={'5px'}>
          <Text mb={2}>Tax</Text>
          <Grid
            width="full"
            justifyItems="center"
            alignItems="center"
            gap={2}
            templateColumns={{
              base: 'repeat(2, 1fr)',
              sm: 'repeat(3, 1fr)', // 3 columns on small screens
              md: 'repeat(4, 1fr)', // 4 columns on medium screens
              lg: 'repeat(5, 1fr)', // 5 columns on large screens
            }}>
            {taxes?.map((tax: Tax, index: number) => (
              <Flex
                key={index}
                justify="center"
                align="center"
                gap={2}
                direction="column">
                <Controller
                  name={`taxes[${index}]`}
                  control={control}
                  render={({ field }) => (
                    <Checkbox
                      {...(field as any)}
                      isChecked={selectedTaxes.includes(tax.id)}
                      onChange={(e) => {
                        const isChecked = e.target.checked;
                        handleTaxChange(tax.id, isChecked);
                        field.onChange(tax.id);
                      }}
                    />
                  )}
                />
                <Text
                  color="gray.500"
                  fontSize="xs"
                  width="100%"
                  textAlign="center">
                  {tax.name} ({tax.rate}%)
                </Text>
              </Flex>
            ))}
          </Grid>
        </Flex>

        {/* two inputs weight and low stock */}
        <Flex justify={'space-between'} align={'center'} mb={4}>
          <Text color={'gray.500'} fontSize={'sm'} width={'150px'}>
            Weight
          </Text>
          <Input
            borderRadius={0}
            _focus={{
              boxShadow: 'none',
            }}
            placeholder="Weight"
            {...register('weight', {
              required: isWeighted ? 'Weight is required' : false,
              pattern: {
                value: /^[0-9]+(\.[0-9]{1,2})?$/,
                message: 'Enter a valid weight',
              },
            })}
          />
        </Flex>
        {errors.weight && (
          <Text color="red.500" fontSize="sm">
            {errors.weight.message as string}
          </Text>
        )}

        <Flex justify={'space-between'} align={'center'} mb={4}>
          <Text color={'gray.500'} fontSize={'sm'} width={'150px'}>
            Low Stock
          </Text>
          <Input
            borderRadius={0}
            _focus={{
              boxShadow: 'none',
            }}
            placeholder="Low Stock"
            {...register('lowStock', {
              pattern: {
                value: /^[0-9]+$/,
                message: 'Enter a valid number',
              },
            })}
          />
        </Flex>
        {errors.lowStock && (
          <Text color="red.500" fontSize="sm">
            {errors.lowStock.message as string}
          </Text>
        )}

        {/* Buttons */}
        <Flex justify="space-between" mt={4}>
          <Button variant="outline" onClick={onClose}>
            Cancel
          </Button>
          <Button
            colorScheme="blue"
            type="submit"
            isLoading={isCreatingProduct || isCreatingStoreProduct}>
            Create
          </Button>
        </Flex>
      </form>
    </Box>
  );
};

export default CreateProductPage;
